import React from 'react';
import Ban from '../../../assets/how/ban.webp'; // Path to your uploaded image

const HeroSection = () => {
  return (
    <div className="relative w-full">
      <img
        src={Ban}
        alt="Why"
        className="w-full h-full object-cover"
      />

    

      
    </div>
  );
};

export default HeroSection;
